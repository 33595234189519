/**
 * Animation is show after 1 second
 */
@keyframes showOpacity {
  0% { opacity: 0.5; max-height: 300px;  }
  50% {  opacity: 1; max-height: 300px;  }
  100% {  opacity: 1; max-height: 800px;  }
}

@keyframes hideOpacity {
  from { opacity: 1;  }
  to {  opacity: 0;  }
}

.appointment__wrapper {
  position: relative;
  z-index: 0;
}

.appointment__iframe {
  position: relative;
  z-index: 5;
  animation: showOpacity 8s;
}

.appointment__loader {
  position: absolute;
  top: 5em;
  left: 0;
  right: 0;
  z-index: 10;
  animation: hideOpacity 8s;
  pointer-events: none;
  opacity: 0;
}