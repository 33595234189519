/*=========================================
   Global
--=========================================*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');


.links-inherit-color a {
  color: inherit;
}

/**
 * Header
 */

.header-order-status {
  font-size: 0.8em;
  text-align: center;
  padding: 0.3em 0.5em;
}

.header-order-status--open {
  background: #008000;
}

.header-order-status--closed {
  background: #333;
}

/**
 * Main
 */

/* Show the Order Number */
.order-number {
  text-align: center;
  font-size: 0.9em;
  padding: 1.5rem 1rem 2rem;
  border-bottom: 1px solid #ccc;
}

.order-number__ids {
  font-size: 3rem;
  line-height: 1.4;
}

/**
 * Footer
 */
 .footer-wrap a,
 .footer-wrap a:visited {
  color: inherit;
  text-decoration: none;
}


/**
 * Login Page
 */
 .login__loading-button .MuiLoadingButton-loadingIndicator {
  color: #fff;
}

/**
 * Categories
 */

.categories-wrap .MuiImageListItem-root {
  border-radius: 15px;
  overflow: hidden;
}

.categories-wrap .MuiImageListItemBar-title {
  font-size: 1.3rem;
  white-space: normal;
}

/**
 * The Cart
 */
.cart-items {
  padding-bottom: 9em;
}

.cart-confirm-order-wrapper {
  position: fixed;
  bottom: 5rem;
  left: 0;
  right: 0;
  max-width: 400px;
  margin: 0 auto;
  box-sizing: content-box;
}

.cart-confirm-order {
  padding: 1em;
  background: #fff;
  border-top: 1px solid hsla(0, 0%, 0%, 0.2);
  box-shadow: 0 -4px 10px rgba(225, 225, 225, 0.5);
}

.cart-item__title {
  font-size: 1.1rem;
  margin: 0 0 0.5em;
}