/**
 * @file
 * custom-search-icon.css
 */

.search-form {
  position: relative;
  z-index: 100;
  margin: 0;
}

.search-form__inner {
  margin: 0;
}

.search-form__icon {
  cursor: pointer;
  position: relative;
  z-index: 20;
  text-indent: -99em;
  text-align: left;
  overflow: hidden;
  opacity: .8;
}

.search-form__keys {
  width: 0;
  opacity: 0;
  top: -.3em;
  position: absolute;
  right: 3em;
  color: transparent;
  border-radius: .1em;
  z-index: 30;
  border: 1px solid transparent;
  transition: all .5s ease 0s;
  -webkit-transition: all .5s ease 0s;
  box-shadow: none;
  background: transparent;
  font-size: 1.1em;
}

.search-form__keys--open {
  /* background: #fff;
  border-color: #ccc; */
  opacity: 1;
  width: 14rem;
  max-width: 90vw;
  /* color: #000;
  padding: .4em .5em;
  box-shadow: 0.5em 0.5em 1em rgb(0 0 0 / 20%); */
}

.search-form__keys input {
  width: 100%;
}

/* On small screens, show the search in the middle of the screen */
/* @media (max-width: 40em) {
  .search-form__keys {
    top: 50%;
    margin: -1em auto;
    left: 0;
    right: 0;
    position: fixed;
  }

  .search-form__keys--open {
    box-shadow: 0.5em 0.5em 30em 30em rgb(0 0 0 / 50%);
  }
} */