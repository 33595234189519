.product-dialog-wrapper {
  min-width: 20rem;
  max-width: 97vw;
  height: 95vh;
}

.product-dialog__content {
  padding: 0.4rem 1rem 1.5rem;
}

/*
.product-dialog__content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.4rem 2rem 1.5rem;
  background: rgba(255,255,255,0.8);
} */